<!-- Order -->
<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
      <!--begin: Orders-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Order Body-->
        <div class="row justify-content-center">
          <div class="col-xl-12">
            <!--begin: Orders Form-->
            <el-form ref="form" :model="form" auto-complete="false">
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  <i class="flaticon-price-tag" style="font-size: 20px"></i>
                  {{ title_text }}
                </h3>
                <el-row>
                  <el-col :span="2">
                    <el-form-item label="PO ID" prop="po_id">
                      <el-input v-model="form.po_number" disabled> </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" :offset="1">
                    <div ref="supplier_id">
                      <el-form-item
                        label="Supplier Company Name"
                        prop="supplier_id"
                      >
                        <el-select
                          ref="supplier_id"
                          v-model="form.supplier_id"
                          filterable
                          clearable
                          remote
                          placeholder="Search Company Name"
                          v-loading="supLoading"
                          style="width: 100%"
                          :remote-method="searchSupplier"
                          auto-complete="nope"
                          @clear="supplierClear()"
                          @change="getSupplierTerms"
                          :class="
                            form.errors.has('supplier_id')
                              ? 'error required'
                              : 'required'
                          "
                        >
                          <el-option
                            v-for="(supplier, index) in supplier_list"
                            :key="index"
                            :label="supplier.company_name"
                            :value="supplier.id"
                            style="height: auto; padding: 10px 20px"
                          >
                          </el-option>
                        </el-select>
                        <span
                          class="form-err"
                          v-if="
                            form.errors.has('supplier_id') ||
                            errorList.includes('supplier_id')
                          "
                          >The supplier Name field is required.</span
                        >
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="4" :offset="1">
                    <div ref="website_id">
                      <el-form-item prop="website" label="Store">
                        <el-select
                          clearable
                          filterable
                          v-model="form.website_id"
                          placeholder="Select Store"
                          style="width: 100%"
                          :allow-create="true"
                          @change="getAddress"
                          :disabled="!form.supplier_id"
                          :class="
                            form.errors.has('website_id')
                              ? 'error required'
                              : 'required'
                          "
                        >
                          <el-option label="All Store" :value="0"
                            >All Store</el-option
                          >
                          <el-option
                            v-for="website in active_website"
                            :key="website.website_id"
                            :label="website.website_name"
                            :value="website.website_id"
                          ></el-option>
                        </el-select>
                        <span
                          class="form-err"
                          v-if="form.errors.has('website_id')"
                          >The Store field is required.</span
                        >
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="3" :offset="1">
                    <div ref="request_date">
                      <el-form-item
                        label="Request Date"
                        prop="request_date"
                        style="margin-bottom: -3px"
                      >
                        <el-date-picker
                          v-model="form.request_date"
                          placeholder="DD-MM-YYYY"
                          type="date"
                          format="dd MMM yyyy"
                          style="width: 100%"
                          request_date
                          :class="
                            form.errors.has('request_date')
                              ? 'error required'
                              : 'required'
                          "
                        >
                        </el-date-picker>
                        <span
                          class="form-err"
                          v-if="form.errors.has('request_date')"
                          >{{ form.errors.get("request_date") }}</span
                        >
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="5" :offset="1" v-if="!this.$route.params.poId">
                    <div ref="created_by">
                      <el-form-item label="Created By" prop="created_by">
                        <el-input
                          v-model="form.created_by"
                          @input.native="capitalize"
                          :class="
                            form.errors.has('created_by')
                              ? 'error required'
                              : 'required'
                          "
                          @input="clearValidations('created_by')"
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                    <span class="form-err" v-if="form.errors.has('created_by')"
                      >The Created By field is requried</span
                    >
                  </el-col>
                </el-row>
                <div class="row">
                  <div class="col-xl-12">
                    <h4 v-if="form.delivery_type == 'Delivery'">Ship To</h4>
                    <h4 v-else>PickUp</h4>
                  </div>
                </div>
                <el-row>
                  <el-col :span="5">
                    <div ref="address">
                      <!-- <el-form-item
                        label="Street No & Street Name"
                        prop="address"
                      >
                        <el-input
                          v-model="form.address"
                          @input.native="capitalize"
                          auto-complete="off"
                          :class="
                            form.errors.has('address')
                              ? 'error required'
                              : 'required'
                          "
                          @input="clearValidations('address')"
                        ></el-input>
                        <span
                          class="form-err"
                          v-if="form.errors.has('address')"
                          >{{ form.errors.get("address") }}</span
                        >
                      </el-form-item> -->
                      <el-form-item label="Address" prop="street">
                          <vue-google-autocomplete
                            ref="supplier_address"
                            v-model="form.address"
                            id="map"
                            classname="form-control el-input__inner"
                            name="address"
                            placeholder="Enter your Address"
                            v-on:placechanged="getAddressData"
                            country="ca"
                          >
                          </vue-google-autocomplete>
                          <span
                            class="form-err"
                            v-if="form.errors.has('address')"
                            >The Address field is required.</span
                          >
                        </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="3" :offset="1">
                    <el-form-item label="Unit No" prop="unit_no">
                      <el-input
                        v-model="form.unit_no"
                        @input.native="capitalize"
                        auto-complete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" :offset="1">
                    <div ref="city_id">
                      <el-form-item label="City" prop="city_id">
                        <el-select
                          ref="city_id"
                          v-model="form.city_id"
                          @change="clearValidations('city_id')"
                          :class="
                            form.errors.has('city_id')
                              ? 'error required'
                              : 'required'
                          "
                        >
                          <el-option
                            v-for="(city, index) in city_list"
                            :key="index"
                            :label="city.city_name"
                            :value="city.city_id"
                          >
                          </el-option>
                        </el-select>
                        <span class="form-err" v-if="form.errors.has('city_id')"
                          >The City field is required.</span
                        >
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="4" :offset="1">
                    <div ref="state_id">
                      <el-form-item label="Province" prop="state_id">
                        <el-select
                          ref="state_id"
                          v-model="form.state_id"
                          @change="getCityListByStateId"
                          :class="
                            form.errors.has('state_id')
                              ? 'error required'
                              : 'required'
                          "
                        >
                          <el-option
                            v-for="(state, index) in state_list"
                            :key="index"
                            :label="state.state_name"
                            :value="state.state_id"
                          >
                          </el-option>
                        </el-select>
                        <span
                          class="form-err"
                          v-if="form.errors.has('state_id')"
                          >The Province field is required.</span
                        >
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="4" :offset="1">
                    <div ref="post_code">
                      <el-form-item
                        label="Postal Code"
                        prop="post_code"
                        :class="
                          form.errors.has('post_code')
                            ? 'error required'
                            : 'required'
                        "
                      >
                        <el-input
                          v-model="form.post_code"
                          :maxlength="7"
                          auto-complete="off"
                          @input="clearValidations('post_code')"
                        ></el-input>
                        <span
                          class="form-err"
                          v-if="form.errors.has('post_code')"
                          >The Postal Code field is required.</span
                        >
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="Terms" prop="term_id">
                      <el-select
                        v-model="form.term_id"
                        placeholder="Select"
                        :disabled="!form.supplier_id"
                      >
                        <el-option class="add-container" value="">
                          <a class="add-drop-btn" @click="createTerms">
                            <i class="el-icon-circle-plus"></i>
                            Add Term
                          </a>
                        </el-option>
                        <el-option
                          v-for="(term, index) in terms"
                          :key="index"
                          :label="term.term_name"
                          :value="term.id"
                          auto-complete="off"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <span class="form-err" v-if="form.errors.has('term_id')">{{
                      form.errors.get("term_id")
                    }}</span>
                  </el-col>                 
                  <el-col :span="5" :offset="1">
                    <el-form-item label="Ship Via" prop="ship_via">
                      <el-select v-model="form.ship_via" placeholder="Select">
                        <el-option
                          v-for="(shipvia, index) in shippingvia"
                          :key="index"
                          :label="shipvia.text"
                          :value="shipvia.text"
                          auto-complete="off"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" :offset="1">
                    <el-form-item label="FOB" prop="fob">
                      <el-input v-model="form.fob"> </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" :offset="1">
                    <el-form-item label="Delivery Type" prop="delivery_type">
                      <el-select v-model="form.delivery_type" placeholder="Select"  @change="changeDeliveryType()">
                        <el-option
                          v-for="(del, index) in delivery_type"
                          :key="index"
                          :label="del.text"
                          :value="del.text"
                          auto-complete="off"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="row">
                  <div class="col-xl-12">
                    <h4>
                      Pricing Details
                      <el-tag
                        size="mini"
                        style="margin-left: 10px"
                        v-if="form.supplier_id"
                      >
                        <a href="#" @click.prevent="addLowStockProduct"
                          >Add Product</a
                        ></el-tag
                      >
                    </h4>
                  </div>
                </div>
                <el-form-item prop="products">
                  <el-table :data="form.products" stripe border fit>
                    <el-table-column label="" width="50">
                      <template slot-scope="scope">
                        <i
                          @click="removeProduct(scope.$index)"
                          class="el-icon-circle-close icon-pointer icon-close"
                        ></i>
                        <i
                          v-if="
                            purchaseorder_list.length > form.products.length
                          "
                          @click="addProduct(scope.$index)"
                          class="el-icon-circle-plus icon-pointer"
                        ></i>
                        <!-- <i
                                        v-else
                                        @click="removeProduct(scope.$index)"
                                        class="el-icon-circle-close icon-pointer icon-close"
                                      ></i> -->
                      </template>
                    </el-table-column>
                    <el-table-column label="Supplier SKU" width="100">
                      <template slot-scope="scope">
                        <span>{{
                          form.products[scope.$index].supplier_sku
                            ? form.products[scope.$index].supplier_sku
                            : form.products[scope.$index].main_supplier.mfg_id
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Product #" width="100">
                      <template slot-scope="scope">
                        <el-select
                          filterable
                          v-model="form.products[scope.$index].product.sku"
                          @change="viewProductDetails($event, scope.$index)"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="(
                              purchase_order, index1
                            ) in purchaseorder_list"
                            :key="index1"
                            :disabled="isExist(purchase_order.product_id)"
                            :label="purchase_order.product.sku"
                            :value="purchase_order.product.sku"
                            auto-complete="off"
                          >
                          </el-option>
                        </el-select>
                        <!-- <span>{{form.products[scope.$index].product.sku}}</span>                                                                                   -->
                      </template>
                    </el-table-column>
                    <el-table-column label="Product Name" :min-width="180">
                      <template slot-scope="scope">
                        <span>{{
                          form.products[scope.$index].product.name
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Type" width="80">
                      <template slot-scope="scope">
                        <el-select
                          filterable
                          v-model="form.products[scope.$index].type_id"
                          placeholder="Select"
                          class="dropdown-add"
                          clearable
                        >
                          <el-option class="add-container" value="">
                            <a class="add-drop-btn" @click="CreateUnit()">
                              <i class="el-icon-circle-plus"></i>
                              Add
                            </a>
                          </el-option>
                          <el-option
                            v-for="(unit, index) in unit_list"
                            :key="index"
                            :label="unit.unit_type"
                            :value="unit.unit_type"
                            auto-complete="nope"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Stock Qty"
                      width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <span>{{
                          form.products[scope.$index].product.stock_quantity
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Max Qty" width="80" align="center">
                      <template slot-scope="scope">
                        <span>{{
                          form.products[scope.$index].product.max_quantity
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Reorder Qty"
                      width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <span>{{
                          form.products[scope.$index].reorder
                            ? form.products[scope.$index].reorder
                            : 0
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Quantity" width="130">
                      <template slot-scope="scope">
                        <el-input-number
                          v-model="form.products[scope.$index].quantity"
                          placeholder="0"
                          :min="0"
                          :step="1"
                          @keyup.native="makeButtonDisable"
                          @change="setProductPrice(scope.$index)"
                        ></el-input-number>
                        <span
                          class="form-err"
                          v-if="
                            productsQtyErrorIds.includes(
                              form.products[scope.$index].product_id
                            )
                          "
                          >Qty cannot be '0'.</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column label="Price" width="80" align="center">
                      <template slot-scope="scope">
                        <span
                          >${{
                            form.products[scope.$index].main_supplier
                              .supplier_cost
                              ? parseFloat(
                                  form.products[scope.$index].main_supplier
                                    .supplier_cost
                                ).toFixed(2)
                              : "0.00"
                          }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column label="Total" width="100" align="center">
                      <template slot-scope="scope">
                        <span v-if="form.products[scope.$index].total"
                          >${{
                            form.products[scope.$index].total
                              ? parseFloat(
                                  form.products[scope.$index].total
                                ).toFixed(2)
                              : "0.00"
                          }}</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                <span class="form-err" v-if="show_product_error"
                  >Please select a Quantity.</span
                >
                <el-row class="pricing-section">
                  <el-col :span="14">
                    <el-form-item
                      label="Other Comments or Special Instructions"
                      prop="comments"
                    >
                      <el-input
                        type="textarea"
                        :rows="3"
                        v-model="form.comments"
                        @input.native="capitalize"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-form :label-position="labelPosition" label-width="150px">
                    <el-col :span="8" :offset="2">
                      <el-form-item label="Sub Total" prop="sub_total">
                        <el-input
                          v-model="form.sub_total"
                          auto-complete="off"
                          disabled
                          class="return_product"
                        ></el-input>
                      </el-form-item>
                      <el-row class="mb-2">
                        <el-col :span="2">
                          <label style="font-weight: bold">Tax</label>
                        </el-col>
                        <el-col :span="8">
                          <el-select
                            filterable
                            v-model="form.tax_per"
                            placeholder="Select"
                            clearable
                            @clear="taxClear()"
                            @change="calculateTotal()"
                            class="return_product tax"
                          >
                            <el-option class="add-container" value="">
                              <a class="add-drop-btn" @click="createTax()">
                                <i class="el-icon-circle-plus"></i>
                                Add Tax
                              </a>
                            </el-option>
                            <el-option
                              v-for="(tax, index) in tax_list"
                              :key="index"
                              :label="
                                tax.tax_name + '(' + tax.tax_amount + '%)'
                              "
                              :value="tax.tax_amount"
                              auto-complete="off"
                            >
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="13" :offset="1">
                          <el-input
                            v-model="form.tax_total"
                            auto-complete="off"
                            :min="0"
                            class="return_product"
                            disabled
                          ></el-input>
                        </el-col>
                      </el-row>
                      <el-form-item label="Total" prop="order_total">
                        <el-input
                          v-model="form.order_total"
                          auto-complete="off"
                          disabled
                          class="return_product"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-form>
                </el-row>
                <el-form-item class="btn-action">
                  <el-button
                    type="primary"
                    :disabled="disable"
                    @click="createPurchaseOrder"
                    >{{ submit_btn_text }}</el-button
                  >
                  <el-button
                    type="warning"
                    @click.prevent="reset()"
                    v-if="!this.$route.params.poId"
                    >Reset</el-button
                  >
                  <el-button
                    type="danger"
                    @click.prevent="cancel()"
                    v-if="this.$route.params.poId"
                    >Cancel</el-button
                  >
                </el-form-item>
              </div>
            </el-form>
            <!--end: Orders Form-->
          </div>
        </div>
        <!--end: Orders Body-->
      </div>
    </div>
    <!--end: Orders-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import {
  searchSupplier,
  getDropDownSupplier,
  getSupplierTerms,
} from "@/api/supplier";
import { getAllProductTypes, getProductUnitType } from "@/api/product";
import { getTax } from "@/api/order";
import {
  getStoreAddress,
  getProductList,
  getPurchaseorderById,
  getPurchaseOrderProduct,
  getPurchaseOrderId,
} from "@/api/purchaseorder";

import CreateSupplier from "@/components/CreateSupplier/add.vue";
import { globalEvents } from "@/utils/globalEvents";
import CreateTerm from "@/components/CreateTerm";
import CreateType from "@/components/CreateType";
import CreateTax from "@/components/CreateTax/addTax.vue";
import { getAllActiveSites } from "@/api/website";
import { getCityList, getStateList, getCityListByStateId,getCityByName,getStateByPrefix } from "@/api/common";
import addLowProduct from "@/components/PurchaseOrder/addPurchaseOrder";
import CreateUnit from "@/components/DropDown/unitType";
import scrollToMandatoryFields from "../../../helpers/ScrollToMandatoryFields";
import { getProduct } from "@/api/product";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "add-purchase-order",
  props: {
    supplier_id: {
      type: Number,
    },
    product_id: {
      type: Number,
    },
  },
  data() {
    return {
      isAdded: false,
      errorList: [],
      productsQtyErrorIds: [],
      disable: false,
      loading: false,
      error: null,
      supLoading: false,
      labelPosition: "right",
      form: new Form({
        po_number: null,
        supplier_id: null,
        request_date: new Date(),
        term_id: null,
        address: null,
        unit_no: null,
        city_id: null,
        state_id: null,
        post_code: null,
        comments: null,
        ship_via: null,
        fob: null,
        created_by: null,
        sub_total: "0.00",
        tax_per: 13,
        tax_total: "0.00",
        order_total: "0.00",
        website_id: null,
        delivery_type:'Delivery',
        products: [
          {
            product_id: null,
            type_id: null,
            quantity: null,
            unit_price: "0.00",
            total: "0.00",
            backorder_quantity: 0,
            main_supplier: [],
            supplier_sku: null,
            supplier_id: null,
            product: [
              {
                sku: null,
                name: null,
                cost_price: "0.00",
                stock_quantity: 0,
              },
            ],
          },
        ],
      }),
      purchaseorder_list: [],
      search: "",
      city_list: [],
      state_list: [],
      supplier_list: [],
      product_list: [],
      terms: [],
      type_list: [],
      tax_list: [],
      active_website: [],
      store_address: [],
      unit_list: [],
      searchKeyword: "",
      shippingvia: [
        { id: 1, text: "Truck" },
        { id: 2, text: "Flight" },
        { id: 3, text: "Ship" },
        { id: 4, text: "Train" },
      ],
      delivery_type:[
        { id: 1, text: "PickUp" },
        { id: 2, text: "Delivery" },
      ],
      submit_btn_text: "Create Purchase Order",
      title_text: "Create Purchase Order",
      api_url: "/purchase-order/create",
      response_msg: "Purchase order created successfully.",
      orderStatus: [
        { id: "pending", text: "Pending Payment" },
        { id: "processing", text: "Processing" },
        { id: "on-hold", text: "On Hold" },
        { id: "completed", text: "Completed" },
        { id: "cancelled", text: "Cancelled" },
      ],
      show_product_error: false,
      show_product_qty_error: false,
      current_product_index: null,
    };
  },
  components: {
    VueGoogleAutocomplete
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Purchase Orders", route: "/purchase-order/list" },
      { title: "Add/Edit Purchase Order" },
    ]);

    globalEvents.$on("termAdded", (term) => {
      if (term) {
        this.terms.push(term);
        this.form.term_id = term.id;
      }
    });

    globalEvents.$on("typeAdded", (type) => {
      if (type) {
        this.type_list.push(type);
        this.form.type_id = type.id;
      }
    });

    globalEvents.$on("unitType", (unitType) => {
      if (unitType) {
        this.unit_list.push(unitType);
      }
    });

    globalEvents.$on("AddTax", (tax) => {
      if (tax) {
        this.form.products[this.current_product_index].tax_list.push(tax);
        this.form.products[this.current_product_index].tax_id = tax.tax_id;
        this.setProductPrice(this.current_product_index);
        this.current_product_index = null;
      }
    });

    globalEvents.$on("addProduct", (product) => {
      let AddProduct = [];
      if (product) {
        product.map((prod) => {
          AddProduct["backorder_quantity"] = prod.products.backorder_quantity;
          AddProduct["product_id"] = prod.products.id;
          AddProduct["stock_quantity"] = prod.products.stock_quantity;
          AddProduct["reorder"] = prod.products.reorder;
          AddProduct["product"] = prod.products;
          AddProduct["quantity"] = null;
          AddProduct["unit_price"] = prod.supplier_cost;
          AddProduct["total"] = "0.00";
          AddProduct["main_supplier"] = prod;
          AddProduct["supplier_sku"] = prod.mfg_id;
          AddProduct["supplier_id"] = prod.supplier;
          AddProduct["type_id"] = prod.products.unit;
          let ProductObj = Object.assign({}, AddProduct);
          this.purchaseorder_list.push(ProductObj);
          this.form.products.push(ProductObj);
          //console.log(AddProduct,prod);
        });
      }
    });
    
  },

  methods: {
    // Selvan added
    getProductById(productId) {
      this.loading = true;
      getProduct(productId).then((response) => {
        const mainSupplier = response.data.data.supplier_list.filter(
          (supplier) =>
            supplier.supplier === Number(this.$route.query.supplierId)
        )[0];
        const _product = {
          stock_quantity: response.data.data.stock_quantity,
          reorder: response.data.data.reorder,
          quantity: 0,
          product_id: response.data.data.id,
          type_id: response.data.data.unit, // Need clarification, there are two ways followed for displaying type in grid
          unit_price: mainSupplier.supplier_cost,
          total: "0.00",
          backorder_quantity: 0,
          main_supplier: mainSupplier,
          supplier_sku: mainSupplier.mfg_id,
          supplier_id: mainSupplier.supplier,
        };
        _product.product = {
          sku: response.data.data.sku,
          name: response.data.data.name,
          cost_price: response.data.data,
          stock_quantity: response.data.data.stock_quantity,
          max_quantity: response.data.data.max_quantity,
          unit: response.data.data.unit,
        };
        const isExist =
          this.form.products.filter(
            (product) => product.product_id === Number(productId)
          ).length > 0;

        if (isExist) {
          let isNotify = true;
          if (this.isAdded === false) {
            if (isNotify) {
              this.$notify({
                title: "Info",
                type: "info",
                message:
                  "Selected supplier product already exist in the Pricing Details",
              });
            }
            isNotify = false;
          }
        } else {
          this.form.products.push(_product);
          this.isAdded = true;
          if (this.isAdded === true) {
            this.$notify({
              title: "Success",
              type: "success",
              message:
                "Selected supplier product is not exist in the Pricing Details, so it has been added",
            });
          }
        }
        this.loading = false;
      });
    },
    //////
    getAddressData: function (addressData) {
      this.addressLoader = true;
      this.form.post_code = addressData.postal_code;
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name);
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix);
      }
      var street = "";
      if (addressData.street_number != null) {
        street = addressData.street_number;
      }

      this.form.address = street + " " + addressData.route;
      this.address = addressData;
      // this.addressLoader = false;
    },
    getCityIdByName(city_name) {
      this.addressLoader = true;
      getCityByName(city_name).then((response) => {
       
          const city_id = response.data.data.city_id;
          const stateId = response.data.data.state_id;
          this.addressLoader = true;
          getCityListByStateId(stateId).then((response) => {
            this.city_list = response.data.data;
            this.form.city_id = city_id;
            this.city_loading = false;
            this.addressLoader = false;
          });
        
        this.addressLoader = false;
      });
    },
    getStateByStatePrefix(state_prefix) {
      this.addressLoader = true;
      getStateByPrefix(state_prefix).then((response) => {
        this.form.state_id = response.data.data.state_id;
       
        this.addressLoader = false;
      });
    },
    handleValidation(index) {
      if (
        this.form.products[index].hasOwnProperty("product_id") &&
        this.form.products[index].quantity >= 1
      ) {
        this.productsQtyErrorIds = this.productsQtyErrorIds.filter(
          (item) => item !== this.form.products[index].product_id
        );
      } else {
        this.productsQtyErrorIds.push(this.form.products[index].product_id);
      }
    },

    supplierClear() {
      this.form.term_id = null;
      this.form.address = null;
      this.form.unit_no = null;
      this.form.city_id = null;
      this.form.state_id = null;
      this.form.post_code = null;
      this.form.comments = null;
      this.form.website_id = null;
      this.supplier_list = [];
    },

    viewProductDetails(event, index) {
      for (var list in this.purchaseorder_list) {
        if (this.purchaseorder_list[list].product.sku == event) {
          this.form.products[index].backorder_quantity =
            this.purchaseorder_list[list].backorder_quantity;
          this.form.products[index].product.name =
            this.purchaseorder_list[list].product.name;
          this.form.products[index].product.cost_price =
            this.purchaseorder_list[list].product.cost_price;
        }
      }
    },

    isExist(product_id) {
      for (var i = 0; i < this.form.products.length; i++) {
        if (this.form.products[i].product_id == product_id) {
          return true;
        }
      }
      return false;
    },
    generatePurchaseId() {
      getPurchaseOrderId().then((response) => {
        this.form.po_number = response.data.data;
      });
    },
    makeButtonDisable() {
      this.disable = true;
    },
    addTableRow: function () {
      this.counter++;
      this.tableRows.push("Table Row " + this.counter);
    },
    cancel() {
      this.$router.push({ path: "/purchase-order/list" });
    },
    reset() {
      this.$refs["form"].resetFields();
      this.form.website_id = "";

      this.form.sub_total = "0.00";
      this.form.tax_total = "0.00";
      this.form.order_total = "0.00";
    },
    getProductUnitType() {
      this.loading = true;
      getProductUnitType().then((response) => {
        this.unit_list = response.data.data;
      });
    },
    addressLabel(address) {
      let formattedAddress = "";
      if (address.company_name) {
        formattedAddress += address.company_name + ", ";
      }
      if (address.address_line_1) {
        formattedAddress += address.address_line_1 + ", ";
      }
      if (address.address_line_2) {
        formattedAddress += address.address_line_2 + ", ";
      }
      if (address.city) {
        formattedAddress += address.city_details.city_name + ", ";
      }
      if (address.state) {
        formattedAddress += address.state_details.state_name + ", ";
      }
      if (address.post_code) {
        formattedAddress += address.post_code + ", ";
      }
      return formattedAddress;
    },
    getCityListByStateId() {
      this.loading = true;
      getCityListByStateId(this.form.state_id).then((response) => {
        this.city_list = response.data.data;
        this.clearValidations("state_id");
        this.loading = false;
      });
    },
    getPurchaseOrderProduct(supplier_id, selectedproducts = null) {
      this.loading = true;
      getPurchaseOrderProduct(supplier_id).then((response) => {
        this.form.products = response.data.data;
        let i = 0;
        this.form.products.map((products) => {
          if (products) {
            this.form.products[i]["total"] = "0.00";
          }
          ++i;
        });
        var purchaseorder = [];
        response.data.data.map(function (value) {
          purchaseorder.push(value);
        });
        this.purchaseorder_list = purchaseorder;
        if (selectedproducts) {
          for (var prod in this.form.products) {
            for (var index in selectedproducts) {
              if (
                selectedproducts[index]["product_id"] ==
                this.form.products[prod]["product_id"]
              ) {
                this.form.products[prod]["quantity"] =
                  selectedproducts[index]["quantity"];
                this.form.products[prod]["unit_price"] =
                  selectedproducts[index]["unit_price"];
                this.form.products[prod]["total"] =
                  selectedproducts[index]["total"];
                this.form.products[prod]["tax_id"] =
                  selectedproducts[index]["tax_id"];
                this.form.products[prod]["type_id"] =
                  selectedproducts[index]["unit"];
                this.form.products[prod]["tax_amount"] =
                  selectedproducts[index]["tax_amount"];
              }
            }
          }
        }
        if (this.$route.query.productId) {
          // Selvan added this to get product details which is coming from Supplier view page
          this.getProductById(this.$route.query.productId);
        }
      });
    },
    getCityList() {
      this.loading = true;
      getCityList().then((response) => {
        this.city_list = response.data.data;
        this.loading = false;
      });
    },
    getStateList() {
      this.loading = true;
      getStateList().then((response) => {
        this.state_list = response.data.data;
        this.loading = false;
      });
    },
    getTax() {
      this.loading = true;
      getTax().then((response) => {
        this.tax_list = response.data.data;
        this.loading = false;
      });
    },
    setProductPrice(index) {
      // Selvan added
      this.handleValidation(index);
      //
      let pid = parseInt(this.form.products[index].product_id);
      let tax_id = parseInt(this.form.products[index].tax_id);
      let unit_price = this.form.products[index].main_supplier.supplier_cost;
      let quantity = parseInt(this.form.products[index].quantity);
      let tax_percentage = 0;
      let unit_tax_amount = 0;
      if (tax_id) {
        tax_percentage = this.getTaxAmountByTaxId(tax_id, index);
      }
      if (tax_percentage > 0) {
        unit_tax_amount = this.calculateTaxAmount(unit_price, tax_percentage);
      }
      this.form.products[index].tax_list = this.getProductTaxListById(pid);
      this.form.products[index].unit_price = unit_price;
      this.form.products[index].type_id =
        this.form.products[index].product.unit;
      this.form.products[index].tax_amount = quantity * unit_tax_amount;
      this.form.products[index].total =
        quantity * unit_price + quantity * unit_tax_amount;
      this.form.products[index].supplier_sku =
        this.form.products[index].main_supplier.mfg_id;
      this.form.products[index].supplier_id =
        this.form.products[index].main_supplier.supplier;
      this.disable = false;
      this.calculateTotal();
    },
    getProductPriceById(product_id) {
      let productPrice = 0;
      if (product_id) {
        this.product_list.map((product) => {
          if (product_id == product.product_id) {
            productPrice = parseFloat(product.supplier_cost);
          }
        });
      }
      return productPrice;
    },
    CreateUnit() {
      this.$modal.show(
        CreateUnit,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    calculateTaxAmount(unit_product_price, tax_percentage) {
      let tax_amount = 0;
      if (tax_percentage > 0) {
        tax_amount = (unit_product_price * tax_percentage) / 100;
      }
      return tax_amount;
    },
    getTaxAmountByTaxId(tax_id, pindex) {
      let tax_amount = 0;
      if (tax_id) {
        this.form.products[pindex].tax_list.map((tax) => {
          if (tax_id == tax.tax_id) {
            tax_amount = parseFloat(tax.tax_amount);
          }
        });
      }
      return tax_amount;
    },
    getProductTaxListById(product_id) {
      let tax_list = 0;
      if (product_id) {
        this.product_list.map((product) => {
          if (product_id == product.product_id) {
            tax_list = product.products.tax_list;
          }
        });
      }
      return tax_list;
    },
    getSupplierTerms() {
      this.product_list = [];
      this.form.products = [];
      this.form.sub_total = "0.00";
      this.form.tax_total = "0.00";
      this.form.order_total = "0.00";
      this.form.website_id = 20;
      this.loading = true;
      if (this.form.supplier_id) {
        this.errorList = this.errorList.filter(
          (item) => item !== "supplier_id"
        );
        this.form.errors.clear("supplier_id");
        getSupplierTerms(this.form.supplier_id)
          .then((response) => {
            this.loading = false;
            this.terms = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.terms = [];
          });
        this.getPurchaseOrderProduct(this.form.supplier_id);
        if(this.form.delivery_type){
          this.changeDeliveryType();
        }  
      }
      this.loading = false;
    },
    getProductTypes() {
      this.loading = true;
      getAllProductTypes()
        .then((response) => {
          this.loading = false;
          this.type_list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.type_list = [];
        });
    },
    getProductTaxById(product_id) {
      let productTax = 0;
      if (product_id) {
        this.product_list.map((product) => {
          if (product_id == product.id) {
            productTax = parseFloat(product.app_tax);
          }
        });
      }
      return productTax;
    },

    searchSupplier(q) {
      if (q !== "") {
        this.supLoading = true;
        searchSupplier(q)
          .then((response) => {
            this.supLoading = false;
            this.supplier_list = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.supLoading = false;
            this.supplier_list = [];
          });
      } else {
        this.supplier_list = [];
      }
    },

    customFilter(item, queryText) {
      const textOne = item.first_name ? item.first_name.toLowerCase() : "";
      const textTwo = item.last_name ? item.last_name.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    productNameFilter(item, queryText) {
      const textOne = item.description ? item.description.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return textOne.indexOf(searchText) > -1;
    },
    productSkuFilter(item, queryText) {
      const textOne = item.sku ? item.sku.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return textOne.indexOf(searchText) > -1;
    },

    handleQuantityCheck() {
      return this.form.products.every((item) => item.quantity >= 1);
    },

    handleMandatoryFields() {
      if (!this.form.supplier_id) {
        this.form.errors.add("supplier_id", "The Supplier name is requried");
        this.errorList.push("supplier_id");
      }
      "address", "city_id", "state_id", "post_code";
      if (!this.form.address) {
        this.form.errors.add("address", "The Address name is requried");
        this.errorList.push("address");
      }
      if (!this.form.city_id) {
        this.form.errors.add("city_id", "The City name is requried");
        this.errorList.push("city_id");
      }
      if (!this.form.state_id) {
        this.form.errors.add("state_id", "The State name is requried");
        this.errorList.push("state_id");
      }
      if (!this.form.post_code) {
        this.form.errors.add("post_code", "The Postcode name is requried");
        this.errorList.push("post_code");
      }
      if (!this.form.website_id) {
        this.form.errors.add("website_id", "The Store is requried");
        this.errorList.push("website_id");
      }
      if (!this.form.request_date) {
        this.form.errors.add("request_date", "The Request data is requried");
        this.errorList.push("request_date");
      }
      if (!this.form.created_by) {
        this.form.errors.add("created_by", "The Created by name is requried");
        this.errorList.push("created_by");
      }

      if (this.errorList.length) {
        return true;
      } else {
        return false;
      }
    },

    createPurchaseOrder: function () {
      let me = this;
      me.show_product_error = false;
      if (
        (!this.handleMandatoryFields() &&
          this.form.order_total == "0.00" &&
          this.form.products.length >= 0) ||
        !this.handleQuantityCheck()
      ) {
        this.productsQtyErrorIds = this.form.products
          .map((item) =>
            item.quantity < 1 || item.quantity === undefined
              ? item.product_id
              : null
          )
          .filter((item2) => item2 !== null);
      } else {
        //this.$showResponse('warning','No Product to Create Purchase Order');
        this.form.request_date = this.$formatDate(
          this.form.request_date,
          "YYYY-MM-DD"
        );
        this.loading = true;
        this.form
          .post(this.api_url)
          .then((response) => {
            if (response.status) {
              this.$router.push({ path: "/purchase-order/list" });
              this.loading = false;
              this.$showResponse("success", response.message);
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error, "erros");
            scrollToMandatoryFields(error, this.$refs);
          });
      }
    },

    addLowStockProduct() {
      this.$modal.show(
        addLowProduct,
        {
          supplier_id: this.form.supplier_id,
          request_po: this.purchaseorder_list,
          products: this.form.products,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "900px",
        }
      );
    },
    createSupplier() {
      this.$modal.show(
        CreateSupplier,
        {},
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "inherit",
          clickToClose: false,
          adaptive: true,
          width: "900px",
        }
      );
    },
    createTerms() {
      this.$modal.show(
        CreateTerm,
        {
          supplier_id: this.form.supplier_id,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: false,
          adaptive: true,
        }
      );
    },
    createProductType() {
      this.$modal.show(
        CreateType,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: false,
          adaptive: true,
        }
      );
    },
    createProductTax(product_id, index) {
      this.current_product_index = index;
      this.$modal.show(
        CreateTax,
        { product_id: product_id },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: false,
          adaptive: true,
        }
      );
    },
    getAllSuppliers() {
      this.loading = true;
      getDropDownSupplier().then((response) => {
        this.supplier_list = response.data.data;
        this.loading = false;
      });
    },
    getAllproductlists() {
      this.loading = true;
      getProductList(this.form.supplier_id).then((response) => {
        this.product_list = response.data.data;
        let AddProduct = [];
        this.product_list.map((product) => {
          if (product.product_id == this.product_id) {
            AddProduct["backorder_quantity"] =
              product.products.backorder_quantity;
            AddProduct["product_id"] = product.products.id;
            AddProduct["stock_quantity"] = product.products.stock_quantity;
            AddProduct["reorder"] = product.products.reorder;
            AddProduct["product"] = product.products;
            AddProduct["main_supplier"] = product;
            AddProduct["supplier_sku"] = product.mfg_id;
            AddProduct["supplier_id"] = product.supplier;
            AddProduct["quantity"] = 0;
            AddProduct["type_id"] = product.products.unit;
            let ProductObj = Object.assign({}, AddProduct);
            this.purchaseorder_list.push(ProductObj);
            this.form.products.push(ProductObj);
          }
        });
        this.loading = false;
      });
    },
    addProduct() {
      this.form.products.push({
        product_id: null,
        type_id: null,
        quantity: null,
        unit_price: "0.00",
        tax_id: null,
        tax_amount: null,
        total: "0.00",
        tax_list: [],
        supplier_sku: null,
        supplier_id: null,
        product: [
          {
            sku: null,
            name: null,
            cost_price: "0.00",
            unit: null,
          },
        ],
      });
    },
    removeProduct(index) {
      this.form.products.splice(index, 1);
      this.purchaseorder_list.splice(index, 1);
      this.calculateTotal();
    },
    calculateTotal() {
      let sub_total = 0;
      let taxPer = this.form.tax_per;
      this.form.products.map((product) => {
        sub_total =
          sub_total +
          (product.unit_price ? product.unit_price : 0) *
            (product.quantity ? product.quantity : 0);
      });

      let tax = (sub_total * taxPer) / 100;
      this.form.tax_total = parseFloat(tax).toFixed(2);
      this.form.sub_total = parseFloat(sub_total).toFixed(2);

      this.form.order_total = parseFloat(sub_total + tax).toFixed(2);
    },
    addExtratax() {
      this.form.total =
        this.form.sub_total - this.form.discount - this.form.credit_amount;
    },
    getAllActiveSites() {
      this.loading = true;
      getAllActiveSites().then((response) => {
        this.active_website = response.data.data;
        this.loading = false;
      });
    },

    clearValidations(key) {
      if (this.errorList.includes(key)) {
        this.errorList = this.errorList.filter((item) => item !== key);
        this.form.errors.clear(key);
      } else if (!this.form[key]) {
        this.errorList.push(key);
        this.form.errors.add(key, `The ${key} is requried`);
      }
    },

    getAddress() {
      this.loading = true;
      // this.product_list = [];
      // this.form.sub_total = "0.00";
      // this.form.tax_total = "0.00";
      // this.form.order_total = "0.00";
      if(this.form.website_id){
        this.form.address = ''
       
      getStoreAddress(this.form.website_id).then((response) => {
        
        if (response.data.data == null) {
          this.form.address = "733 Progress Avenue";
          this.form.city_id = 188;
          this.form.state_id = 8;
          this.form.post_code = "M1H 2W7";
          this.loading = false;
        }
        this.form.address = response.data.data.address;
        this.form.city_id = response.data.data.city_id;
        this.form.state_id = response.data.data.state_id;
        this.form.post_code = response.data.data.post_code;
        // Clear Validation
        ["address", "city_id", "state_id", "post_code", "website_id"].map(
          (key) => this.clearValidations(key)
        );
        this.loading = false;
      });
    }else{
      ["address", "city_id", "state_id", "post_code", "website_id"].map(
          (key) => this.clearValidations(key)
        );
      this.form.address = "733 Progress Avenue";
      this.form.city_id = 188;
      this.form.state_id = 8;
      this.form.post_code = "M1H 2W7";
      this.loading = false;
      }
      //this.$refs.supplier_address.update(this.form.address);
    },
    changeDeliveryType(){
      this.loading = true;
      if(this.form.delivery_type == 'PickUp'){
        this.$refs.supplier_address.clear();
        this.supplier_list.map((supplier)=>{
          if(this.form.supplier_id == supplier.id){
            this.form.address = supplier.street?supplier.street:'';
            this.form.city_id = supplier.city_id?supplier.city_id:'';
            this.form.state_id = supplier.state_id?supplier.state_id:'';
            this.form.post_code = supplier.postal_code?supplier.postal_code:'';
          }
       
        })
        
      }else{
        this.getAddress();
      }
      this.loading = false;
    }
  },
  watch: {
    /*search(value) {
      if (!value) {
        return
      }         
      this.loading = true      
      this.searchSupplier(value);
    },  */
  },
  created() {
    this.generatePurchaseId();
    let poId = this.$route.params.poId;
    this.getStateList();
    this.getCityList();
    this.getAllSuppliers();
    this.getProductTypes();
    this.getAllActiveSites();
    this.getProductUnitType();
    this.getTax();

    if (parseInt(poId) > 0) {
      this.submit_btn_text = "Update Order";
      this.title_text = "Update";
      this.action = "update";
      this.response_msg = "Purchase order updated successfully.";
      this.getAllproductlists();
      getPurchaseorderById(poId).then((response) => {
        this.api_url = "purchase-order/update/" + poId;
        var purchaseorder = [];
        response.data.data.products.map(function (value) {
          purchaseorder.push(value);
        });
        this.purchaseorder_list = purchaseorder;
        this.form = new Form(response.data.data);

        getSupplierTerms(this.form.supplier_id)
          .then((response) => {
            this.loading = false;
            this.terms = response.data.data;
          })
          .catch(() => {
            this.loading = false;
            this.terms = [];
          });
      });
    }
    if (this.supplier_id) {
      this.form.supplier_id = this.supplier_id;
      this.getSupplierTerms();
    }

    // Selvan added this lines for
    if (this.$route.query.supplierId) {
      this.form.supplier_id = this.$route.query.supplierId; // Selvan modified this
      this.getSupplierTerms();
    }
    //

    if (this.product_id > 0) {
      this.getAllproductlists();
    }
  },
  computed: {},
};
</script>

<style>
.pricing-section .el-form-item__label {
  line-height: 30px;
}
.btn-action {
  margin-top: 20px;
}
.tax .el-input__icon {
  line-height: 0%;
}
.return_product .el-input__inner {
  /* padding-left: 0px;
      padding-right: 8px; */
  border: 1px solid #3699ff;
  text-align: end !important;
}
</style>
