<template>
  <div >
    <div slot="header" class="modal-header popup-header">
			<span class="modal-title-popup"><i class="fa fa-file" aria-hidden="true"></i>{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Orders-->
          <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
            <div class="row justify-content-center">
              <div class="col-xl-12">
                <div class="m-bot-10">
                      <div class="row">           
                        <div class="col-sm-6 col-xs-12">
                          <b-form-group class="mb-3">
                            <b-input-group size="sm">
                              <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                                @keyup.enter.native="(filter)?fetchData(1):''" 
                                auto-complete="nope"
                              ></b-form-input>
                              <span class="search-icon" v-if="filter" id="clear-button">
                                <i class="fas fa-times" @click="filter =''"></i>
                                <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                              </span>
                              <b-input-group-append>
                                <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </div>      
                        <div class="col-sm-3 col-xs-12">
                         <el-checkbox  class="temp-check"
                                   true-label="1"
                                    false-label="0"
                                    v-model="all_supplier" label="Show All Supplier Product" @change="showAllProduct()" ></el-checkbox>   
                        </div>   
                         <div class="col-sm-3 col-xs-12">
                         <el-checkbox  class="temp-check"
                                   true-label="1"
                                    false-label="0"
                                    v-model="low_stock" label="Show Low Stock Product" @change="showAllProduct()" ></el-checkbox>   
                        </div>     
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <el-table :data="product" border element-loading-text="Loading" style="width: 100%" 
                        v-loading="loading" 
                        :row-key="getRowKey" 
                        @selection-change="handleSelectionChange"
                        ref="multipleTable">
                          <el-table-column
                            type="selection"
                            :reserve-selection="true"
                            width="55">
                          </el-table-column>
                          <el-table-column label="Active"  width="80" align="center">
                            <template slot-scope="scope">
                             <span v-if="scope.row.products.record_status == 1"><b style="color:green">Active</b></span>
                             <span v-else><b style="color:red">InActive</b></span>
                            </template>
                          </el-table-column> 
                        <el-table-column label="Supplier SKU"  :min-width="200">
                            <template slot-scope="scope">
                               <span v-if="scope.row.mfg_id">{{scope.row.mfg_id}}</span>
                            </template>
                          </el-table-column> 
                           <el-table-column label="SKU"  :min-width="200">
                            <template slot-scope="scope">
                              {{scope.row.products.sku}}
                            </template>
                          </el-table-column>               
                          <el-table-column label="Product Name"  :min-width="200">
                            <template slot-scope="scope">
                              {{scope.row.products.name}}
                            </template>
                          </el-table-column> 
                         
                          <el-table-column label="Supplier UPC" width="130" >
                            <template slot-scope="scope">
                              <span v-if="scope.row.upc_code">{{scope.row.upc_code}}</span>
                            </template>
                          </el-table-column>   
                          <el-table-column label="In-Stock" width="100" align="center">
                            <template slot-scope="scope">
                              <span>{{scope.row.products.stock_quantity}}</span>
                            </template>
                          </el-table-column> 
                            <el-table-column label="Min" width="100" align="center">
                            <template slot-scope="scope">
                              <span>{{scope.row.products.min_quantity}}</span>
                            </template>
                          </el-table-column> 
                            <el-table-column label="Max" width="100" align="center">
                            <template slot-scope="scope">
                              <span>{{scope.row.products.max_quantity}}</span>
                            </template>
                          </el-table-column>  
                          <el-table-column label="Cost Price" width="120" align="center">
                            <template slot-scope="scope">
                              <span><b>$ {{parseFloat(scope.row.supplier_cost).toFixed(2)}}</b></span>
                            </template>
                          </el-table-column>     
                        </el-table>
                      </div>
                </div>  
                <div class="row">
                  <div class="col-sm-12">
                    <el-pagination v-if="!loading" style="margin-top:15px;"
                        background
                        layout="sizes, prev, pager, next"
                        @size-change="handleSizeChange"
                        @current-change="fetchData" 
                        :page-count="pagination.last_page" 
                        :page-sizes="[10, 25, 50, 100]"
                        :page-size="pagination.per_page"
                        :current-page.sync="pagination.current_page" 
                        :total="pagination.total_rows"
                        >
                    </el-pagination>
                  </div>
                </div> 
               
                   <div style="margin-top:20px;float:right">                    
                       <button class="rounded-3 btn btn-sm btn-primary font-weight-bold text-uppercase" @click.prevent="addProduct()">Add Product</button>                    
                    </div> 
               
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  
</template>

<script>
import {getSupplierProductList} from "@/api/supplier";
import { globalEvents } from '@/utils/globalEvents';
export default {
  name: "addproduct",
  props:{
    supplier_id : {
      type : Number,
      required:false
    },
    label:{
      type: Object,
      default() {
        return {button:'Create',title:'Add Product'}
      }
	  },
    products:{
       type: Array,
       required: true
    }
  },
    data(){
        return {
              loading:false,
              show: true,
              multipleSelection: [],
              product:[],
              pagination:{
              total_rows: 1,
              current_page: 1,
              last_page:null,
              per_page: 10,
            },
            select_order_number:null,
            select_orderId:null,
            filter:null,
            all_supplier:0,
            low_stock:0,
            product_Id : [],
        }
    },

    methods: {
      fetchData(p){
        this.getProductList(p);
      },
      handleSelectionChange(rows) {
        
        this.multipleSelection = rows;     
        this.select_order_number = this.multipleSelection.length;
        this.select_orderId = [];
            if (rows) {
                rows.forEach(row => {
                    if (row) {
                        this.select_orderId.push(row.orderid);
                    }
                });
            } 
      },
      closeForm() {    	
      	//globalEvents.$emit('CreditAdded', false)
      	this.$emit('close')    	
	  },
    getRowKey(row){
        return row.id
      },
      getProductList(p){
         this.loading = true
           let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
         if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        if(this.selectedWebsite){
          params.selected_website = this.selectedWebsite;
        }
        params.productId = this.product_Id;

        let supplier_id = 'all';        
        if(this.all_supplier == 0){
          supplier_id = this.supplier_id;
        }

        if(this.low_stock != 0){
          params.low_stock = 1;
        }

        getSupplierProductList(supplier_id,params).then(response => {
            this.product = response.data.data.data;                       
            this.pagination.current_page = response.data.data.current_page
            this.pagination.last_page = response.data.data.last_page
            this.pagination.total_rows = response.data.data.total
            this.loading = false           
        })
      },
       handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
      addProduct(){
         globalEvents.$emit('addProduct', this.multipleSelection)
         this.$emit('close');
      },
      showAllProduct(){        
        this.fetchData(1);
      }
    },
    created(){
      this.fetchData(1);
      this.products.map((product)=>{      
        this.product_Id.push(product.product_id);
      });

    },
     watch: {
      filter: function (n) {
        if (!n) {
          this.fetchData(1);
        }
      },
  },

    
}
</script>