<template>
 <el-card shadow="never" :body-style="{ margin: '20px' }" v-loading="form.submiting"  >
    <div slot="header">
        <span class="modal-title-popup">Create Product Type</span>
        <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
    </div>
    <div class="card card-custom">
        <div class="card-body p-0">
          <el-form :model="form"  ref="form" label-position="left" label-width="150px" :disabled="form.submiting">
          
          <el-form-item label="Type Name" prop="type_name">
              <el-input v-model="form.type_name" @input.native="capitalize" placeholder="Type Name" auto-complete="off" ></el-input>
              <span class="form-err" v-if="form.errors.has('type_name')" >{{form.errors.get('type_name')}}</span>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click.prevent="submitForm" :icon="form.submiting?'el-icon-loading':''">Create</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-card>
</template>

<script>
import Form from '@/core/services/api.form.services'
import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'create-term',
  data() {
    return {
      loading : false,
      form : new Form({
        type_name : null
      })
    }
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    submitForm() {
      const formURL = 'product/type/create'
      //const msg = 'The term has been created'

      this.form.post(formURL).then((response) => {
        //this.$showResponse('success', msg);
        globalEvents.$emit('typeAdded', response.data)
        this.$emit('close')
      }).catch((error) => {
        console.log(error);
      })
    }
  },
}

</script>

<style scoped>
.option-info{
  float: left; color: #8492a6; font-size: 13px; clear:both;line-height: 20px;
}
.option-info i {
  color: #27304A;
}
.title{
  font-size: 16px;
  font-weight: 600;
  color: #27304A;
  display: inline-block;
  margin-bottom: 20px;
}
.event-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #ebeef5;
}
.icon-pointer{
  font-size: 20px; cursor: pointer;color: #616e8e;
}
</style>
